<template>
    <public_header :title="header_title" right_title="" jump_url=""></public_header>
    <div class="wa90">
        <el-form class="mt30">
            <el-form-item label="标题">
                <el-input v-model="title" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="提醒日期">
                <div class="column-flex-center" style="height: 100%">
                    <el-input class="relative" size="mini" readonly v-model="final_date" placeholder="选择日期"></el-input>
                    <calendar class="calendar-component" @back="back" :date="schedule_date" v-if="refresh"></calendar>
                </div>
            </el-form-item>
            <el-form-item label="提醒时间">
                <el-time-select
                        v-model="remind_time"
                        start="00:00"
                        step="00:30"
                        end="23:30"
                        placeholder="请选择"
                        size="mini"
                ></el-time-select>
            </el-form-item>
            <el-form-item label="日期类型" @change="change_date">
                <el-radio-group v-model="date_type">
                    <el-radio :label="1">阳历</el-radio>
                    <el-radio :label="2">农历</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="每年重复">
                <el-switch v-model="repeat" inline-prompt active-text="是" inactive-text="否" :active-value="2"
                           :inactive-value="1"></el-switch>
            </el-form-item>

            <el-form-item label="事件描述">
                <el-input type="textarea" v-model="describe"></el-input>
            </el-form-item>
        </el-form>
        <div class="center">
            <el-button class="mt20" type="primary" @click="save_click">保存</el-button>
        </div>
    </div>

    <!--<div style="width: 100%">
      <baidu-calendar />
    </div>-->

</template>

<script>

  import Calendar from "@c/calendar";
  import {schedule_save, schedule_detail} from "@net/blotter";
  import Public_header from "@c/header";
  import {select_day} from "@u/custom";

  let timer = null
  export default {
    name: "affair_edit",
    components: {Public_header, Calendar},
    data() {
      return {
        date_type: 1,
        repeat: 1,
        describe: '',
        title: '',
        remind_time: '',
        schedule_date: '',
        date_arr: [],
        final_date: '',
        header_title: '新增事件',
        id: '',
        refresh: true
      }
    },
    created() {
      if (this.$route.query.hasOwnProperty('id')) {
        this.header_title = '编辑事件'
        this.id = this.$route.query.id
        this.get_detail()
      }
      if (!this.Utils.has_login()) {
        this.Utils.go_login(this.$route.path, this.$route.query)
        return
      }
    },
    methods: {
      get_detail() {
        let obj = {id: this.id}
        obj.is_login = 1
        schedule_detail(obj).then(res => {
          if (res.business_code == 200) {
            let arr = res.data
            let lunarDay=select_day(arr.remind_date)
            this.date_type = arr.date_type
            this.repeat = arr.schedule_type
            this.describe = arr.describe
            this.title = arr.title
            this.remind_time = arr.remind_time
            this.date_arr = {s_date: arr.remind_date, l_date: lunarDay.IMonthCn + lunarDay.IDayCn}
            this.schedule_date = arr.remind_date
            this.change_date()
            this.refresh = false
            this.$nextTick(function () {
              this.refresh = true
            })
          }
        })
      },
      back(type, val) {
        if (type == 'date') {
          this.date_arr = val
          this.change_date()
          this.schedule_date = val.s_date
        }
      },
      change_date() {
        if (this.date_arr.length == 0) {
          return
        }
        if (this.date_type == 1) {
          this.final_date = this.date_arr.s_date
        } else {
          this.final_date = this.date_arr.l_date
        }
      },
      save_click() {
        let rule = {
          'title': '事件标题不能为空',
          'schedule_date': '请选择提醒日期',
          'date_type': '请选择日期类型',
          'repeat': '请选择是否每年重复提醒',
          'remind_time': '请选择提醒时间',
        };
        let obj = {}
        let arr = ['title', 'schedule_date', 'date_type', 'repeat', 'remind_time'];
        for (let i = 0; i < arr.length; i++) {
          if (this[arr[i]] == '') {
            if (rule.hasOwnProperty(arr[i])) {
              this.$toast(rule[arr[i]]);
            }
            return false;
          } else {
            obj[arr[i]] = this[arr[i]];
          }
        }
        let no_required = ['id']
        no_required.forEach((item, index) => {
          if (this[item] != '') {
            obj[item] = this[item]
          }
        })
        obj.describe = this.describe;
        obj.is_login = 1;
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          schedule_save(obj).then(res => {
            if (res.business_code == 200) {
              this.$toast('保存成功');
              this.$router.go(-1)
            } else {
              this.$toast(res.msg);
            }
          })
          clearTimeout(timer)
        }, 800)

      }
    }
  }
</script>
<style lang="scss" scoped>
    ::v-deep(.relative) {
        width: 200px;
    }
</style>
<style scoped>
    .calendar-component {
        position: absolute;
        top: 10%;
        left: 0;
    }
</style>