<template>
    <div class="calendar-dom">
        <div class="flex" style="height:100%">
           <!-- <van-button size="mini" color="#ea5503" plain @click="show_shadow">选择日期</van-button>-->
            <div class="transparent-btn"  @click="show_shadow"></div>
        </div>

        <el-dialog custom-class="this-dialog" :title="title" :model-value="dialog" @closed="dialog=false" width="100%">
            <div class="flex-end">
                <el-date-picker
                        v-model="value"
                        type="month" size="mini"
                        placeholder="快速定位月份"
                ></el-date-picker>
            </div>
            <el-calendar class="calendar" v-model="value" style="width: 100%">
                <template #dateCell="{data}">
                    <div :class="{ selected : isSelected(data) }"
                         class="date-item text-center column-flex-around" @click="select_day(data.day)">
                        <div class="solar font14">{{ data.day.split('-')[2] }}</div>
                        <div class="lunar text-omitted" :class="{ festival : isFestival(data) }">{{ solarToLunar(data) }}
                        </div>
                    </div>
                </template>
            </el-calendar>
            <div class="flex-end">
                <el-button class="mt10" size="mini" type="primary" @click="confirm_day">确定</el-button>
            </div>

        </el-dialog>
    </div>

    <!--<el-button @click="test">测试</el-button>-->
</template>

<script>
  import calendar from '@js/calendar'

  export default {
    name: "calendar",
    data() {
      return {
        // 设置value，会自动显示value对应月份，可以设置为selectedDates的第一个日期
        value: '',
        title: '',
        // 根据selectedDates设置选中日期
        //selectedDates: ['2021-10-26','2020-04-15','2020-04-24']
        selectedDates: [],
        dialog: false,
        final_date:''
      }
    },
    props:{
      date:{
        type:String,
        default:false
      }
    },
    emit:['back'],
    created() {

    },
    methods: {
      // 是否选中日期
      isSelected: function (slotData) {
        return this.selectedDates.includes(slotData.day)
      },
      // 是否节假日
      isFestival(slotData) {
        let solarDayArr = slotData.day.split('-');
        let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])

        // 公历节日\农历节日\农历节气
        let festAndTerm = [];
        festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival)
        festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival)
        festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term)
        festAndTerm = festAndTerm.join('')

        return festAndTerm != ''
      },
      // 公历转农历
      solarToLunar(slotData) {
        let solarDayArr = slotData.day.split('-');
        let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])
        // 农历日期
        //let lunarMD = lunarDay.IMonthCn + lunarDay.IDayCn
        let lunarMD = ''
        if (lunarDay.IDayCn == '初一') {
          lunarMD = lunarDay.IMonthCn
        } else {
          lunarMD = lunarDay.IDayCn
        }

        // 公历节日\农历节日\农历节气
        let festAndTerm = [];
        festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival)
        festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival)
        festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term)
        festAndTerm = festAndTerm.join('')
        return festAndTerm == '' ? lunarMD : festAndTerm
      },
      test() {
        this.value = '2021-04-09'
      },
      show_shadow() {
        this.dialog = true
        if(this.date!=''){
          this.value=this.date
          this.select_day(this.date)
        }else {
          let day2 = new Date();
          day2.setTime(day2.getTime());
          let s2 =this.Utils.formatDate(day2,1) //day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate();
          this.value = s2.split(' ')[0]
          this.select_day(s2)
        }
      },
      select_day(day) {
        let solarDayArr = day.split('-');
        let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])
        let festAndTerm = [];
        festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival)
        festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival)
        festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term)
        festAndTerm = festAndTerm.join('')
        let l_date=lunarDay.IMonthCn + lunarDay.IDayCn
        if (festAndTerm != '') {
          this.title= l_date+ ' • ' + festAndTerm
        } else {
          this.title = l_date
        }
        return l_date
      },
      confirm_day() {
        //console.log(this.Utils.formatDate(this.value,1));
        this.dialog=false
        let sel_date=this.Utils.formatDate(this.value,1)
        sel_date=sel_date.split(' ')[0]
        this.final_date=sel_date
        let obj={
          s_date:sel_date,
          l_date:this.select_day(sel_date)
        }
        this.$emit('back','date',obj)
      }
    }
  }
</script>

<style scoped>
    .selected {
        color: red;
    }

    .date-item {
        height: 100%;
    }

    .solar {
        line-height: 20px;
    }

    .lunar {
        line-height: 20px;
        font-size: 10px;
        -webkit-line-clamp: 1;
    }
</style>
<style lang="scss" scoped>
    .calendar-dom {
        ::v-deep(.el-dialog__body){
            padding-top:0 ;
        }
        ::v-deep(.el-calendar__body) {
            padding: 0;
        }

        ::v-deep(.el-calendar-table .el-calendar-day) {
            padding: 0;
            height: 50px;
        }

        ::v-deep(.el-calendar-table thead th) {
            padding: 0
        }

        ::v-deep(.el-calendar__header) {
            padding: 0 20px 10px 20px;
        }
    }
    .transparent-btn{
        width: 200px;
        height: 30px;
    }
</style>
